import * as React from 'react';

import { Link } from 'components/link/Link';

import s from './Header.scss';

interface IHeaderProps {
  children: React.ReactNode;
  transparent: boolean;
}

export const Header = ({ children, transparent }: IHeaderProps) => {
  const headerStyles = transparent ? s(s.header, s.header__transparent) : s.header;
  return (
    <header className={headerStyles}>
      <div className={s.header__container}>
        <div className={s.header__content}>
          <Link
            to="/"
            className={s.header__logo}
          >
            Vefnir
          </Link>

          <div className={s.header__navigation}>
            {children}
          </div>
        </div>
      </div>
    </header>
)};
