const title = 'Vefnir';
const description = 'Vefrit Félags um átjándu aldar fræði. Ritið er elsta rafræna fræðitímaritið sem gefið er út á Íslandi';

const favicon = require('assets/images/favicon.ico');
const share = require('assets/images/banner2.png');

export const helmet = {
  title,
  titleTemplate: '%s | Vefnir',
  htmlAttributes: { lang: 'is' },
  meta: [
    { name: 'description', content: description },
    { name: 'viewport', content: 'width=device-width, initial-scale=1, user-scalable=no' },
    { name: 'apple-mobile-web-app-status-bar-style', content: 'black' },
    { name: 'msapplication-navbutton-color', content: '#000' },
    { name: 'msapplication-TileColor', content: '#000' },
    { name: 'theme-color', content: '#000' },

    { property: 'og:title', content: title },
    { property: 'og:image', content: share },
    { property: 'og:image:alt', content: description },
  ],
  link: [
    { rel: 'icon', type: 'image/x-icon', href: favicon },
  ],
};
