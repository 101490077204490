import * as React from 'react';
import Helmet from 'react-helmet';

import Facebook from 'assets/svg/facebook.svg';


import { helmet } from 'utils/helmet';
import { Header } from 'components/header/Header';
import { Link as HeaderLink } from 'components/header/Link';
import { Footer } from 'components/footer/Footer';
import { Devtools } from 'components/devtools/Devtools';

import s from './AppLayout.scss';

interface IAppLayoutProps {
  children: React.ReactNode;
}

const isDev = process.env.NODE_ENV === 'development';

export default ({ children }: IAppLayoutProps) => (
  <div className={s.layout}>
    <Helmet {...helmet} />

    <Header transparent>
      <HeaderLink
        name="Hefti"
        to="/hefti"
        transparent
      />
      <HeaderLink
        name="Höfundar"
        to="/hofundar"
        transparent
      />
      <HeaderLink
        name="Um Vefni"
        to="/um-vefni"
        transparent
      />
    </Header>

    {children}

    <Footer
      info="ISSN 1670-3588"
      social={[
        { icon: <Facebook />, to: 'https://www.facebook.com/F%C3%A9lag-um-%C3%A1tj%C3%A1ndu-aldar-fr%C3%A6%C3%B0i-205782410183622/' },
      ]}
    />

    {isDev && <Devtools />}
  </div>
);
